.modal-module__headline___3VU1d {
  color: #2c242c;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px; }

.modal-module__text___19KDS {
  color: #2c242c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px; }

.modal-module__input___2-QQ9 {
  border-radius: 2px;
  border: 1px solid #a9a9a9;
  background-color: #ffffff;
  height: 35px;
  padding-left: 15px;
  outline: none;
  width: 100%; }

.modal-module__button___3OoXE {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: #0076ff;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  border: none;
  margin-top: 20px; }
  .modal-module__button___3OoXE:hover {
    background-color: #1a84ff; }

.modal-module__active___11EGk {
  color: #0ecc65;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 6px; }
