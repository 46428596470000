.switcher-module__switcher___UwVzR {
  width: 33px;
  height: 16px;
  border-radius: 17px;
  border: 2px solid #a9a9a9;
  background: white;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer; }

.switcher-module__ball___3F-2r {
  width: 10px;
  height: 10px;
  background-color: #2c242c;
  position: absolute;
  border-radius: 50%;
  top: 1px;
  left: 2px;
  transform: translateX(0px);
  transition: 0.1s transform; }

.switcher-module__active___1l5zj {
  transform: translateX(15px);
  transition: 0.1s transform;
  cursor: pointer; }
